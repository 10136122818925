import * as React from "react"
import Layout from "../components/layout"

const SzolgaltatasokPage = () => {

  return <Layout>
    Szolgáltatások
  </Layout>
}

export default SzolgaltatasokPage
